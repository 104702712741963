import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import useRichText from "../hooks/useRichTexts";
import Layout from '../fragments/layout/Layout'

const useLegal = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulLegal {
        edges {
          node {
            avisoLegal {
              raw
            }
          }
        }
      }
    }
  `)

  return data
}

const Legal = () => {
  const data = useLegal()

  return (
    <Layout>
      <div className="legal-privacy">
        <h1>Aviso legal</h1>
        <p>{ useRichText(data.allContentfulLegal.edges[0].node.avisoLegal) }</p>
      </div>
    </Layout>
  )
}

export default Legal